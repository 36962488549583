<template>
    <div>
        <div v-if="helperText" class="c-calculation-results__helper-text">
            <div class="c-card-solid c-card-solid--green-light">
                <h5>{{ t("Comparison advice") }}</h5>
                <!-- eslint-disable vue/no-v-html -->
                <div v-html="helperText"></div>
                <!--eslint-enable-->
            </div>
        </div>
        <div class="c-calculation-results">
            <div
                v-if="!results"
                class="c-calculation-results__header c-calculation-results__header--basic"
            >
                <div class="c-calculation-results__header-image">
                    <SvgComponent name="service-package-basic" />
                </div>
                <h3 class="t-h5">{{ servicePackages.headerBasis.title }}</h3>
                <div
                    v-if="servicePackages.headerBasis.text"
                    class="u-margin-top-tiny"
                >
                    <p class="t-paragraph">
                        {{ servicePackages.headerBasis.text }}
                    </p>
                </div>
            </div>

            <div
                v-if="!results"
                class="c-calculation-results__header c-calculation-results__header--complete"
            >
                <div class="c-calculation-results__header-image">
                    <SvgComponent name="service-package-complete" />
                </div>
                <h5 class="t-h5">{{ servicePackages.headerComplete.title }}</h5>
                <div
                    v-if="servicePackages.headerComplete.text"
                    class="u-margin-top-tiny"
                >
                    <p class="t-paragraph">
                        {{ servicePackages.headerComplete.text }}
                    </p>
                </div>
            </div>

            <div
                v-for="(item, key) in servicePackages.packages"
                :key="key"
                :class="[
                    'c-calculation-results__package ',
                    `c-calculation-results__package--${key}`,
                    {
                        'c-calculation-results__package--selected':
                            item.selected,
                        'c-calculation-results__package--greyed-out':
                            !item.active,
                        'c-calculation-results__package--current':
                            item.handle === currentPackage,
                    },
                ]"
            >
                <div class="c-calculation-results__package-top">
                    <span
                        v-if="item.selected"
                        class="c-calculation-results__package-label c-calculation-results__package-label--advice"
                    >
                        {{ t("Our advice") }}
                    </span>
                    <span
                        v-if="item.handle === currentPackage"
                        class="c-calculation-results__package-label c-calculation-results__package-label--current"
                    >
                        {{ t("Current") }}
                    </span>
                </div>
                <div class="c-calculation-results__package-bottom">
                    <!-- eslint-disable -->

                    <h3
                        class="c-calculation-results__package-header"
                        v-html="getNameTemplate(item.name)"
                    />
                    <!-- eslint-enable -->
                    <div
                        v-if="results"
                        class="c-calculation-results__package-total-price"
                    >
                        <p class="c-calculation-results__package-pretitle">
                            {{ t("For you") }}
                        </p>
                        <h3
                            v-if="item.active"
                            class="c-calculation-results__package-title"
                        >
                            {{
                                currency(
                                    actionDiscount &&
                                        item.handle === "klokFocus"
                                        ? results[key].resultedCost - 250
                                        : results[key].resultedCost
                                )
                            }}
                        </h3>
                        <h3 v-else class="c-calculation-results__package-title">
                            {{ t("Does not apply") }}
                        </h3>
                    </div>
                    <div
                        v-if="actionDiscount && item.handle === 'klokFocus'"
                        class="c-calculation-results__package-discount"
                    >
                        {{ t("including €250 discount.") }}
                    </div>
                    <div class="c-calculation-results__package-usps">
                        <ul class="c-checkmark-list">
                            <li
                                v-for="(usp, index) in item.usps"
                                :key="index"
                                class="c-checkmark-list__item"
                            >
                                <span
                                    :class="[
                                        'c-checkmark-list__icon',
                                        {
                                            'c-checkmark-list__icon--greyed-out':
                                                !item.active,
                                        },
                                    ]"
                                    ><SvgComponent name="check" /></span
                                >{{ usp }}
                            </li>
                        </ul>
                    </div>
                    <div class="c-calculation-results__package-cost">
                        <div class="c-calculation-results__package-cost-item">
                            <p class="t-paragraph t-paragraph--tiny">
                                {{ t("You pay per clock transaction") }}
                            </p>
                            <p
                                v-if="item.prices.productOmzetKlok"
                                class="t-paragraph t-paragraph--tiny t-paragraph--bold u-flex-1-0-auto u-text-right"
                            >
                                {{ percentage(item.prices.productOmzetKlok) }}
                            </p>
                            <p
                                v-else
                                class="t-paragraph t-paragraph--tiny t-paragraph--bold u-flex-1-0-auto u-text-right"
                            >
                                n.v.t.
                            </p>
                        </div>
                        <div class="c-calculation-results__package-cost-item">
                            <p class="t-paragraph t-paragraph--tiny">
                                {{
                                    t(
                                        "On the value of your auction presales, you pay"
                                    )
                                }}
                            </p>
                            <p
                                v-if="item.prices.productOmzetKlokVerkoop"
                                class="t-paragraph t-paragraph--tiny t-paragraph--bold u-flex-1-0-auto u-text-right"
                            >
                                {{
                                    percentage(
                                        item.prices.productOmzetKlokVerkoop
                                    )
                                }}
                            </p>
                            <p
                                v-else
                                class="t-paragraph t-paragraph--tiny t-paragraph--bold u-flex-1-0-auto u-text-right"
                            >
                                n.v.t.
                            </p>
                        </div>

                        <div class="c-calculation-results__package-cost-item">
                            <p class="t-paragraph t-paragraph--tiny">
                                {{ t("You pay per direct transaction") }}
                            </p>
                            <p
                                class="t-paragraph t-paragraph--tiny t-paragraph--bold u-flex-1-0-auto u-text-right"
                            >
                                {{
                                    percentage(
                                        item.prices.productOmzetDirecteVerkoop
                                    )
                                }}
                            </p>
                        </div>

                        <div class="c-calculation-results__package-cost-item">
                            <p class="t-paragraph t-paragraph--tiny">
                                {{
                                    t(
                                        "Volume advantage based on annual product turnover"
                                    )
                                }}
                            </p>
                        </div>

                        <div
                            class="c-calculation-results__package-cost-item c-calculation-results__package-cost-item--small"
                        >
                            <p class="t-paragraph t-paragraph--tiny">
                                {{ item.volumeLabels.volumeLabel2 }}
                            </p>
                            <p
                                v-if="item.volumeDiscounts.volume2"
                                class="t-paragraph t-paragraph--tiny t-paragraph--bold u-flex-1-0-auto u-text-right"
                            >
                                -{{ percentage(item.volumeDiscounts.volume2) }}
                            </p>
                            <p
                                v-else
                                class="t-paragraph t-paragraph--tiny t-paragraph--bold u-flex-1-0-auto u-text-right"
                            >
                                {{ t("n.v.t.") }}
                            </p>
                        </div>

                        <div
                            class="c-calculation-results__package-cost-item c-calculation-results__package-cost-item--small"
                        >
                            <p class="t-paragraph t-paragraph--tiny">
                                {{ item.volumeLabels.volumeLabel3 }}
                            </p>
                            <p
                                v-if="item.volumeDiscounts.volume3"
                                class="t-paragraph t-paragraph--tiny t-paragraph--bold u-flex-1-0-auto u-text-right"
                            >
                                -{{ percentage(item.volumeDiscounts.volume3) }}
                            </p>
                            <p
                                v-else
                                class="t-paragraph t-paragraph--tiny t-paragraph--bold u-flex-1-0-auto u-text-right"
                            >
                                {{ t("n.v.t.") }}
                            </p>
                        </div>
                        <div
                            class="c-calculation-results__package-cost-item c-calculation-results__package-cost-item--small"
                        >
                            <p class="t-paragraph t-paragraph--tiny">
                                {{ item.volumeLabels.volumeLabel4 }}
                            </p>
                            <p
                                v-if="item.volumeDiscounts.volume4"
                                class="t-paragraph t-paragraph--tiny t-paragraph--bold u-flex-1-0-auto u-text-right"
                            >
                                -{{ percentage(item.volumeDiscounts.volume4) }}
                            </p>
                            <p
                                v-else
                                class="t-paragraph t-paragraph--tiny t-paragraph--bold u-flex-1-0-auto u-text-right"
                            >
                                {{ t("n.v.t.") }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { t, currency, percentage } from "../../filters";

const props = defineProps({
    servicePackages: {
        type: Object,
        required: true,
    },
    results: {
        type: Object,
        default: null,
    },
    actionDiscount: {
        type: Boolean,
        default: false,
    },
    currentPackage: {
        type: String,
        default: "",
    },
    helperText: {
        type: Object,
        default: () => ({}),
    },
});
const getNameTemplate = (name) => {
    return name.split(" ").join("&nbsp;<i>") + "</i>";
};
const currentPackagePrice = computed(() => {
    if (props.results) {
        const currentPackage = props.results[props.currentPackage];

        if (!currentPackage) {
            return null;
        }
        return currentPackage.resultedCost;
    }
    return null;
});

const selectedPackagePrice = computed(() => {
    let allPackages = {};
    let selectedItem = {};
    if (props.servicePackages) {
        allPackages = props.servicePackages.packages;
        selectedItem = Object.values(allPackages).find(
            (item) => item.selected === true
        );
        if (!selectedItem) {
            return null;
        }
    }

    if (props.results) {
        return props.results[selectedItem.handle].resultedCost;
    }
    return null;
});
const helperText = computed(() => {
    if (!currentPackagePrice.value) return;
    if (currentPackagePrice.value === selectedPackagePrice.value) {
        return props.helperText.equal;
    }
    if (currentPackagePrice.value > selectedPackagePrice.value) {
        return props.helperText.lower;
    }
    return props.helperText.higher;
});
</script>

<style lang="scss" scoped>
.c-calculation-results {
    display: grid;
    grid-template-columns: 1fr;

    @include tablet-landscape {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 20px;
    }

    &__header {
        border-radius: 6px;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 16px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 16px;

        @include tablet-landscape {
            padding-left: 40px;
            padding-right: 40px;
        }

        @include widescreen {
            padding-left: 64px;
            padding-right: 64px;
        }

        &--basic {
            background-color: $green-light;
            margin-top: 40px;

            @include tablet-landscape {
                grid-column: 1 / span 2;
            }
        }

        &--complete {
            background-color: $purple-light;
            grid-row: 4 / span 1;
            margin-top: 100px;

            @include tablet-landscape {
                grid-row: 1 / span 1;
                grid-column: 3 / span 2;
                margin-top: 40px;
            }
        }
    }

    &__header-image {
        margin-top: -40px;
        margin-bottom: 16px;
    }

    &__package {
        margin-bottom: 8px;
        display: flex;
        flex-direction: column;
    }
    &__helper-text {
        @include full-grid;
        .c-card-solid {
            grid-column: 4 / span 8;
        }
        margin-bottom: 24px;
    }

    &__package-top {
        height: 40px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        display: flex;
        gap: 16px;
        justify-content: center;
        align-items: flex-start;
    }

    &__package-bottom {
        border-left: 1px solid $grey-light;
        border-right: 1px solid $grey-light;
        border-bottom: 1px solid $grey-light;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
    }

    &__package--directFocus &__package-top {
        background-color: $green-600;
    }

    &__package--directFlex &__package-top {
        background-color: $green-400;
    }

    &__package--klokFocus &__package-top {
        background-color: $purple-600;
    }

    &__package--klokFlex &__package-top {
        background-color: $purple-400;
    }

    &__package-header {
        padding-right: 16px;
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        padding-top: 16px;
        margin-bottom: 16px;
    }

    &__package-total-price {
        padding-left: 16px;
        padding-right: 16px;
        text-align: center;
        background-color: $grey-light;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-bottom: 16px;
    }

    &__package-pretitle {
        font-family: $title-font-stack;
        font-size: 18px;
        margin-bottom: 2px;
    }

    &__package-title {
        font-size: 24px;
    }

    &__package-usps {
        padding-bottom: 16px;
        padding-right: 16px;
        padding-left: 16px;
    }

    &__package-cost {
        margin-top: auto;
    }

    &__package-cost-item {
        padding-right: 16px;
        padding-left: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
        border-top: 1px solid $grey-light;
        display: flex;
        justify-content: space-between;
        column-gap: 8px;

        &--small {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    &__package--current {
        grid-row: 1 / span 1;
        border: 4px solid $green;
        border-radius: 6px;

        @include tablet-landscape {
            grid-row: auto;
        }
    }
    &__package--selected {
        grid-row: 1fr;
        border: 4px solid $orange;
        border-radius: 6px;

        @include tablet-landscape {
            grid-row: auto;
        }
    }
    &__package--selected &__package-top,
    &__package--current &__package-top {
        border: 0px;
        border-radius: 0px;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
    }
    &__package--selected &__package-bottom,
    &__package--current &__package-bottom {
        border: 0px;
        border-radius: 2px;
    }
    &__package-discount {
        margin-top: -16px;
        margin-bottom: 16px;
        text-align: center;
        font-weight: 600;
        line-height: 24px;
        padding: 6px 0;
    }

    &__package--selected &__package-discount {
        background-color: $orange;
        color: $white;
    }
    &__package--current &__package-label {
        background-color: $green;
    }
    &__package--selected &__package-label {
        background-color: $orange;
    }

    &__package--selected &__package-label,
    &__package--current &__package-label {
        display: block;
        color: $white;
        font-size: 9px;
        font-weight: 700;
        text-transform: uppercase;
        font-family: $micro-font-stack;
        padding-top: 8px;
        padding-right: 12px;
        padding-bottom: 8px;
        padding-left: 12px;
    }

    &__package--greyed-out &__package-top {
        background-color: $grey-light;
    }

    &__package--greyed-out {
        color: $grey;
        grid-row: 4 / span 1;

        @include tablet-landscape {
            grid-row: auto;
        }
    }
}
</style>
