<template>
    <div>
        <nav
            class="navigation"
            :class="{
                'navigation--active': activeItem,
                ['navigation--' + modifier]: modifier,
            }"
            tabindex="0"
            @keydown.esc="handleEscape"
        >
            <div class="navigation__container">
                <slot
                    name="main-content"
                    :active-item="activeItem"
                    :handle-click-menu-item="handleClickMenuItem"
                    :handle-click-menu-item-first-level="
                        handleClickMenuItemFirstLevel
                    "
                    :handle-mouse-over-menu-item="handleMouseOverMenuItem"
                ></slot>
            </div>
        </nav>
        <div
            v-if="activeItem"
            class="overlay"
            @click="setMenuItemActive('')"
        ></div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { debounce } from "lodash";
import { userDeviceHasHover } from "./../../mixins";

defineProps({
    modifier: {
        type: String,
        default: "",
    },
});

const activeItem = ref("");

const setMenuItemActive = (menuItem) => {
    activeItem.value = menuItem;
};

const handleClickMenuItem = (menuItem) => {
    setMenuItemActive(menuItem);
};

const handleClickMenuItemFirstLevel = (slug, url, nested) => {
    if (userDeviceHasHover() || !nested) {
        setMenuItemActive("");
        window.location = url;
        return;
    }

    setMenuItemActive(slug);
};

const handleEscape = () => {
    setMenuItemActive("");
};

const handleMouseOverMenuItem = debounce((menuItem) => {
    setMenuItemActive(menuItem);
}, 300);
</script>
