<template>
    <div class="c-tool">
        <div class="c-tool__progression-bar">
            <span
                class="c-tool__progression"
                :style="{ width: `${progression}%` }"
            ></span>
        </div>
        <div class="c-tool__steps">
            <div
                v-for="(step, index) in steps"
                :key="index"
                class="c-tool__step"
                :class="{ 'c-tool__step--active': index === activeStep }"
            >
                <div class="c-tool__introduction">
                    <div class="c-tool__title">
                        <span class="c-tool__step-number">{{ index + 1 }}</span>
                        <h4 class="t-h4">{{ step.title }}</h4>
                    </div>
                    <div v-if="step.subTitle" class="c-tool__header">
                        <h4 class="t-h4">{{ step.subTitle }}</h4>
                    </div>
                    <div class="c-tool__description">
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <div class="t-redactor" v-html="step.description"></div>
                    </div>
                </div>

                <div v-if="step.type === 'question'" class="c-tool__fields">
                    <div
                        v-for="field in step.fields"
                        :key="field.name"
                        class="c-tool__field"
                    >
                        <InputCurrencyComponent
                            v-if="
                                field.type === 'number' &&
                                showThirdLevelInput(field)
                            "
                            :model-value="field.value ? true : 'false'"
                            :name="field.name"
                            :label="field.label"
                            :initial-value="field.value"
                            @update:modelValue="field.value = $event"
                        />

                        <InputRadioComponent
                            v-if="
                                field.type === 'radio' && showSecondLevelInputs
                            "
                            :model-value="field.value ? true : 'false'"
                            :name="field.name"
                            :label="field.label"
                            :options="field.options"
                            :initial-value="field.value"
                            :has-options-inline="hasOptionsInline(field)"
                            @update:modelValue="field.value = $event"
                        />
                    </div>

                    <div v-if="shouldBecomeMember" class="u-mt-24">
                        <!-- eslint-disable vue/no-v-html -->
                        <div
                            class="t-redactor"
                            v-html="steps[0].messageNonMember"
                        />
                        <!-- eslint-enable -->
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isLastStep" class="c-tool__result">
            <CalculationResults
                :service-packages="filteredServicePackages"
                :results="results"
            />
        </div>
        <div class="c-tool__buttons">
            <button
                v-if="currentStep.hasBackbutton"
                class="c-button-solid c-button-solid--black c-button-solid--reverse"
                @click="handleClickPrevious"
            >
                <span class="c-button-solid__arrow"></span>

                {{ t("Previous step") }}
            </button>
            <button
                v-if="!isLastStep"
                :disabled="!currentStepIsValid"
                class="c-button-solid c-button-solid--orange"
                @click="handleClickNext"
            >
                <span class="c-button-solid__arrow"></span>

                {{ t("Next step") }}
            </button>

            <a
                v-if="shouldBecomeMember"
                class="c-button-solid c-button-solid--orange"
                :href="steps[0].ctaNonMember.url"
                :target="steps[0].ctaNonMember.target"
                @click="() => customEvent('geen_lid_adviestool_pakket')"
            >
                <span class="c-button-solid__arrow"></span>

                {{ steps[0].ctaNonMember.text }}
            </a>
        </div>
    </div>
</template>

<script setup>
import { t } from "../../filters";

import clone from "lodash/clone";

import { computed, ref, onMounted, watch } from "vue";

const props = defineProps({
    servicePackages: {
        type: Object,
        required: true,
    },
    stepsContent: {
        type: Array,
        required: true,
    },
});

const activeStep = ref(0);
const steps = ref([
    {
        type: "question",
        hasBackbutton: false,
        title: "",
        description: "",
        messageNonMember: "",
        ctaNonMember: null,
        event: "next_adviestool_pakket",
        fields: [
            {
                name: "benJeLid",
                type: "radio",
                required: true,
                options: [
                    {
                        label: t("Yes, I am a member"),
                        value: true,
                    },
                    {
                        label: t("No, I am not a member"),
                        value: false,
                    },
                ],
                value: null,
            },
        ],
    },

    {
        type: "question",
        hasBackbutton: true,
        title: "",
        description: "",
        fields: [
            {
                name: "productOmzetKlok",
                label: t("Product turnover clock"),
                required: true,
                type: "number",
                value: null,
            },
            {
                name: "productOmzetKlokVerkoop",
                label: t("Product turnover clock pre sales"),
                type: "number",
                required: true,
                value: null,
            },
        ],
    },

    {
        type: "question",
        hasBackbutton: true,
        title: "",
        description: "",
        fields: [
            {
                name: "productOmzetDirecteVerkoop",
                label: t("Product turnover direct sales"),
                type: "number",
                value: null,
            },
            {
                name: "isDitInclusiefBVO",
                label: t("Does this include BVO?"),
                type: "radio",
                required: true,
                options: [
                    { label: t("Yes"), value: "yes" },
                    { label: t("No"), value: "no" },
                ],
                value: null,
            },
            {
                name: "wiltUDitMeenemenOmVolumevoordeelTeBerekenenBVO",
                label: t("How much BVO trading do you do annually?"),
                type: "number",
                value: 0,
            },
            {
                name: "isDitInclusiefWatJeAfrekentMetCollegaKwekers",
                label: t(
                    "Does this include what you settle with fellow growers?"
                ),
                type: "radio",
                required: true,
                options: [
                    { label: t("Yes"), value: "yes" },
                    { label: t("No"), value: "no" },
                ],
                value: null,
            },
            {
                name: "wiltUDitMeenemenOmVolumevoordeelTeBerekenenMetCollegas",
                label: t("How much do you trade with fellow growers annually?"),
                type: "number",
                value: 0,
            },
        ],
    },
    // {
    //     type: "question",
    //     hasBackbutton: true,
    //     title: "",
    //     description: "",
    //     fields: [
    //         {
    //             name: "benJeGecertificeert",
    //             label: t("Are you or will you be FSI certified in 2025?"), //Bent u FSI gecertificeerd of laat u zich in 2025 certificeren?
    //             type: "radio",
    //             required: false,
    //             options: [
    //                 { label: t("Yes, I am"), value: "yes" },
    //                 { label: t("No, but I will"), value: "optional" },
    //                 { label: t("No, I will not"), value: "no" },
    //             ],
    //         },
    //     ],
    // },

    {
        type: "result",
        hasBackbutton: true,
        title: "Voorlopig advies",
        subTitle: "",
        description: "",
    },
]);

const volumeBrackets = ref({
    volume1: {
        min: 0,
        max: 2000000,
        percentage: {
            directFocus: 0,
            directFlex: 0,
            klokFocus: 0,
            klokFlex: 0,
        },
    },
    volume2: {
        min: 2000000,
        max: 4000000,
        percentage: {
            directFocus: 0,
            directFlex: 0,
            klokFocus: 0,
            klokFlex: 0,
        },
    },
    volume3: {
        min: 4000000,
        max: 12000000,
        percentage: {
            directFocus: 0,
            directFlex: 0,
            klokFocus: 0,
            klokFlex: 0,
        },
    },
    volume4: {
        min: 12000000,
        max: -1,
        percentage: {
            directFocus: 0,
            directFlex: 0,
            klokFocus: 0,
            klokFlex: 0,
        },
    },
});

const prices = ref({
    directFocus: {
        productOmzetKlok: 0,
        productOmzetKlokVerkoop: 0,
        productOmzetDirecteVerkoop: 0,
    },
    directFlex: {
        productOmzetKlok: 0,
        productOmzetKlokVerkoop: 0,
        productOmzetDirecteVerkoop: 0,
    },
    klokFocus: {
        productOmzetKlok: 0,
        productOmzetKlokVerkoop: 0,
        productOmzetDirecteVerkoop: 0,
    },
    klokFlex: {
        productOmzetKlok: 0,
        productOmzetKlokVerkoop: 0,
        productOmzetDirecteVerkoop: 0,
    },
});

const currentStep = computed(() => {
    return steps.value[activeStep.value];
});

const isLastStep = computed(() => {
    return activeStep.value + 1 === steps.value.length;
});

const progression = computed(() => {
    return ((activeStep.value + 1) / steps.value.length) * 100;
});

const currentStepIsValid = computed(() => {
    if (currentStep.value.type !== "question") {
        return true;
    }

    return currentStep.value.fields.every((field) => {
        if (field.required && field.type === "radio") {
            return field.value;
        }

        if (field.type === "number") {
            return field.value !== null;
        }

        return true;
    });
});

const showSecondLevelInputs = computed(() => {
    if (currentStep.value.type !== "question") {
        return false;
    }

    const hasNestedFields = currentStep.value.fields.some((field) => {
        return (
            field.name === "isDitInclusiefBVO" ||
            field.name === "isDitInclusiefWatJeAfrekentMetCollegaKwekers"
        );
    });

    if (!hasNestedFields) {
        return true;
    }

    return currentStep.value.fields.some((field) => {
        return field.name === "productOmzetDirecteVerkoop" && field.value > 0;
    });
});

const values = computed(() => {
    const inputs = {};

    steps.value.forEach((step) => {
        if (step.type === "question") {
            step.fields.forEach((field) => {
                inputs[field.name] = field.value;
            });
        }
    });

    return inputs;
});

const intermediateCalculations = computed(() => {
    const totalVolumeDirect =
        values.value.productOmzetDirecteVerkoop +
        values.value.wiltUDitMeenemenOmVolumevoordeelTeBerekenenBVO +
        values.value.wiltUDitMeenemenOmVolumevoordeelTeBerekenenMetCollegas;

    const totalVolume =
        totalVolumeDirect +
        values.value.productOmzetKlokVerkoop +
        values.value.productOmzetKlok;

    const volumes = {
        volume1: 0,
        volume2: 0,
        volume3: 0,
        volume4: 0,
    };

    Object.keys(volumeBrackets.value).forEach((volume) => {
        if (
            volumeBrackets.value[volume].max === -1 &&
            totalVolume > volumeBrackets.value[volume].min
        ) {
            volumes[volume] = totalVolume - volumeBrackets.value[volume].min;
            return;
        }

        if (
            totalVolume > volumeBrackets.value[volume].min &&
            totalVolume > volumeBrackets.value[volume].max
        ) {
            volumes[volume] =
                volumeBrackets.value[volume].max -
                volumeBrackets.value[volume].min;
            return;
        }

        if (
            totalVolume > volumeBrackets.value[volume].min &&
            totalVolume < volumeBrackets.value[volume].max
        ) {
            volumes[volume] = totalVolume - volumeBrackets.value[volume].min;
            return;
        }
    });

    return {
        totalVolumeDirect,
        totalVolume,
        volumes,
    };
});

const results = computed(() => {
    const results = {};

    Object.keys(props.servicePackages.packages).forEach((servicePackage) => {
        const discount = {
            total: 0,
            volume1: 0,
            volume2: 0,
            volume3: 0,
            volume4: 0,
        };

        Object.keys(volumeBrackets.value).forEach((volume) => {
            discount[volume] =
                intermediateCalculations.value.volumes[volume] *
                volumeBrackets.value[volume].percentage[servicePackage];
        });

        discount.total =
            discount.volume1 +
            discount.volume2 +
            discount.volume3 +
            discount.volume4;

        const cost = {
            productOmzetKlok:
                values.value.productOmzetKlok *
                prices.value[servicePackage].productOmzetKlok,
            productOmzetKlokVerkoop:
                values.value.productOmzetKlokVerkoop *
                prices.value[servicePackage].productOmzetKlokVerkoop,
            productOmzetDirecteVerkoop:
                intermediateCalculations.value.totalVolumeDirect *
                prices.value[servicePackage].productOmzetDirecteVerkoop,
        };

        cost.total =
            cost.productOmzetKlok +
            cost.productOmzetKlokVerkoop +
            cost.productOmzetDirecteVerkoop;

        results[servicePackage] = {
            volumeDiscount: discount,
            cost,
            resultedCost: cost.total - discount.total,
        };
    });

    return results;
});

const recommendedPackage = computed(() => {
    if (
        values.value.productOmzetKlok === 0 &&
        values.value.productOmzetKlokVerkoop === 0
    ) {
        return "directFocus";
    }

    const ratioKlok =
        (100 / intermediateCalculations.value.totalVolumeDirect) *
        (values.value.productOmzetKlok + values.value.productOmzetKlokVerkoop);

    if (intermediateCalculations.value.totalVolume < 250000 && ratioKlok > 20) {
        return "klokFocus";
    }

    return ["directFlex", "klokFlex"].reduce((prev, current) =>
        results.value[prev].resultedCost < results.value[current].resultedCost
            ? prev
            : current
    );
});

const filteredServicePackages = computed(() => {
    const clonedServicePackages = clone(props.servicePackages);

    Object.keys(clonedServicePackages.packages).forEach((servicePackage) => {
        recommendedPackage.value === servicePackage
            ? (clonedServicePackages.packages[servicePackage].selected = true)
            : (clonedServicePackages.packages[servicePackage].selected = false);
    });

    values.value.productOmzetKlok || values.value.productOmzetKlokVerkoop
        ? (clonedServicePackages.packages.directFocus.active = false)
        : (clonedServicePackages.packages.directFocus.active = true);

    return clonedServicePackages;
});

const shouldBecomeMember = computed(() => {
    return values.value.benJeLid === false;
});

const setFieldValue = (name, value = null) => {
    currentStep.value.fields.find((field) => field.name === name).value = value;
};

watch(
    () => values.value.productOmzetDirecteVerkoop,
    (newValue, oldValue) => {
        if (newValue && oldValue) return;

        if (oldValue === 0 && newValue) {
            setFieldValue("isDitInclusiefBVO");
            setFieldValue("isDitInclusiefWatJeAfrekentMetCollegaKwekers");
        }

        if (newValue === 0) {
            setFieldValue(
                "wiltUDitMeenemenOmVolumevoordeelTeBerekenenMetCollegas",
                0
            );
            setFieldValue("wiltUDitMeenemenOmVolumevoordeelTeBerekenenBVO", 0);
            setFieldValue("isDitInclusiefBVO", "yes");
            setFieldValue(
                "isDitInclusiefWatJeAfrekentMetCollegaKwekers",
                "yes"
            );
        }
    }
);

watch(
    () => values.value.isDitInclusiefBVO,
    (newValue) => {
        if (newValue === "no") {
            setFieldValue("wiltUDitMeenemenOmVolumevoordeelTeBerekenenBVO");
        }

        if (newValue === "yes") {
            setFieldValue("wiltUDitMeenemenOmVolumevoordeelTeBerekenenBVO", 0);
        }
    }
);

watch(
    () => values.value.isDitInclusiefWatJeAfrekentMetCollegaKwekers,
    (newValue) => {
        if (newValue === "no") {
            setFieldValue(
                "wiltUDitMeenemenOmVolumevoordeelTeBerekenenMetCollegas"
            );
        }

        if (newValue === "yes") {
            setFieldValue(
                "wiltUDitMeenemenOmVolumevoordeelTeBerekenenMetCollegas",
                0
            );
        }
    }
);

watch(isLastStep, (newVal) => {
    if (newVal) {
        const dataLayer = (window.dataLayer = window.dataLayer || []);
        dataLayer.push({
            event: "adviestool_resultaat",
            resultaat_pakket: recommendedPackage.value,
        });
    }
});

onMounted(() => {
    props.stepsContent.forEach((step, index) => {
        steps.value[index] = { ...steps.value[index], ...step };
    });

    Object.keys(volumeBrackets.value).forEach((volumeBracket) => {
        Object.keys(props.servicePackages.packages).forEach(
            (servicePackage) => {
                volumeBrackets.value[volumeBracket].percentage[servicePackage] =
                    props.servicePackages.packages[
                        servicePackage
                    ].volumeDiscounts[volumeBracket];
            }
        );
    });

    Object.keys(props.servicePackages.packages).forEach((servicePackage) => {
        prices.value[servicePackage] =
            props.servicePackages.packages[servicePackage].prices;
    });
});

const handleClickPrevious = () => {
    if (activeStep.value === 0) {
        return;
    }
    if (window) {
        const dataLayer = (window.dataLayer = window.dataLayer || []);
        dataLayer.push({
            event: "previous_adviestool_pakket",
            question_pakket: steps.value[activeStep.value - 1].fields[0].name,
        });
    }

    activeStep.value--;
};
const handleClickNext = () => {
    if (activeStep.value + 1 === steps.value.length) {
        return;
    }
    if (window) {
        const dataLayer = (window.dataLayer = window.dataLayer || []);
        dataLayer.push({
            event: "next_adviestool_pakket",
            question_pakket: steps.value[activeStep.value].fields[0].name,
        });
    }

    activeStep.value++;
};
const customEvent = (GAEvent) => {
    if (window) {
        const dataLayer = (window.dataLayer = window.dataLayer || []);
        dataLayer.push({
            event: GAEvent,
        });
    }
};
const showThirdLevelInput = (field) => {
    if (currentStep.value.type !== "question") {
        return false;
    }

    if (field.name === "wiltUDitMeenemenOmVolumevoordeelTeBerekenenBVO") {
        return (
            values.value.productOmzetDirecteVerkoop &&
            values.value.isDitInclusiefBVO === "no"
        );
    }

    if (
        field.name === "wiltUDitMeenemenOmVolumevoordeelTeBerekenenMetCollegas"
    ) {
        return (
            values.value.productOmzetDirecteVerkoop &&
            values.value.isDitInclusiefWatJeAfrekentMetCollegaKwekers === "no"
        );
    }

    return true;
};
const hasOptionsInline = (field) => {
    return (
        field.name === "isDitInclusiefBVO" ||
        field.name === "isDitInclusiefWatJeAfrekentMetCollegaKwekers"
    );
};
</script>

<style lang="scss" scoped>
.c-tool {
    @include full-grid;
    padding-bottom: 40px;

    @include tablet-landscape {
        padding-top: 40px;
    }

    &__step {
        display: none;

        &--active {
            display: block;
        }
    }

    &__progression {
        background-color: $green;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 4px;
        width: 0%;
        display: block;
        transition: width 0.3s ease-in-out;
    }

    &__progression-bar {
        position: relative;
        background-color: $grey;
        height: 4px;
        display: block;
        grid-column: side-start/side-end;
        margin-bottom: 28px;

        @include tablet-landscape {
            height: 1px;
            margin-bottom: 40px;
        }
    }

    &__title {
        display: flex;
        margin-bottom: 24px;

        position: relative;
        &::after {
            content: "";
            height: 4px;
            background-color: $green;
            width: 24px;
            position: absolute;
            bottom: -8px;
            left: 0;
            right: 0;

            @include tablet-landscape {
                width: 160px;
                bottom: -12px;
            }
        }
    }

    &__description {
        margin-bottom: 16px;
    }

    &__header {
        margin-bottom: 16px;
        margin-top: 36px;
    }

    &__step-number {
        height: 24px;
        width: 24px;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-family: $title-font-stack;
        background-color: $green;
        margin-right: 8px;

        @include tablet-landscape {
            height: 32px;
            width: 32px;
            font: 21px;
        }
    }

    &__steps {
        grid-column: main-start/main-end;
        margin-bottom: 16px;

        @include tablet-landscape {
            margin-bottom: 24px;
            grid-column: 4 / span 8;
        }

        @include widescreen {
            grid-column: 5 / span 6;
        }
    }

    &__buttons {
        grid-column: main-start/main-end;
        display: flex;
        justify-content: flex-start;
        column-gap: 16px;

        @include tablet-landscape {
            grid-column: 4 / span 8;
        }

        @include widescreen {
            grid-column: 5 / span 6;
        }

        & > button {
            width: 100%;
            flex-grow: 1;
            flex-basis: 0;

            @include tablet {
                width: auto;
                flex-grow: 0;
                flex-basis: auto;
            }
        }
    }

    &__result {
        grid-column: main-start/main-end;
        margin-bottom: 48px;

        @include widescreen {
            grid-column: 3 / -3;
        }
    }
}
</style>
