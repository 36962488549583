<template>
    <ais-hits v-slot="{ items }" :transform-items="orderHitsByLanguage">
        <switcher-links :items="items" />
    </ais-hits>
</template>

<script setup>
import { createLink } from "../../serviceDetailLink.js";
import { ref } from "vue";

const props = defineProps({
    language: {
        type: String,
        default: "",
    },
});

const labels = ref({ NL: "Nederlands", EN: "English", DE: "Deutsch" });
const order = ["NL", "EN", "DE"];

const orderHitsByLanguage = (hits) => {
    //order hits in the same order as labels
    const ordered = hits.sort((a, b) => {
        return order.indexOf(a.languageCode) - order.indexOf(b.languageCode);
    });

    return ordered.map((hit) => {
        return {
            title: hit.title,
            label: labels.value[hit.languageCode],
            url: createLink(hit.title, hit.number, hit.languageCode),
            languageCode: hit.languageCode.toLowerCase(),
            current: hit.languageCode === props.language,
        };
    });
};
</script>
